import React from "react";
import { articles } from "../../constants/constants";
import { useParams } from "react-router-dom";
const ArticleDetailCard = () => {
  let { id } = useParams();
  const articleDetails = articles.filter(i => i.id === id)
  return (
    <div class="article-details">
      <h1 class="sec_title">{articleDetails[0].heading}</h1>
      <h5 className="text-primary py-3">{articleDetails[0].date}</h5>
      {articleDetails[0].details.map((content) => (
        content.startsWith("/heading") ? (
          <h3 className="py-4">{content.replace("/heading", "")}</h3>
        ) : (
          <p className="py-2" dangerouslySetInnerHTML={{ __html: content.replace(/\n/g, "<br />") }} />
        )
        
      ))}
    </div>
  );
};

export default ArticleDetailCard;