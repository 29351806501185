import React, { useRef, useState } from 'react'
import emailjs from 'emailjs-com';
const ContactForm = () => {
    const [err, setError] = useState(null)
    const formRef = useRef()
    const [response, setResponse] = useState(null)
    const resetForm = () => {
        formRef.current.reset();
        setTimeout(() => {
            setResponse(null)
        }, 5000)
    }
    const sendEmail = (e) => {
        e.preventDefault();
        let errors = {};

        const fieldNames = {
            name: 'Full Name',
            emailAddress: 'Email Address',
            contact: 'Contact',
            message: 'Message'
        };
        const requiredFields = ['name', 'emailAddress', 'contact', 'message'];

        for (const field of requiredFields) {
            const value = e.target[field].value.trim();
            if (!value) {
                // Use the field as a key in the errors object
                errors[field] = `Please fill in the ${fieldNames[field]}  field.`;
            }
        }
        setError(errors);
        if (Object.keys(errors).length === 0) {
            const serviceID = 'service_nu2tww4';
            const templateID = 'template_tcd37u8';
            const publicKey = 'IqMFFU3Z2aSszwIQ-'

            const formData = {
                name: e.target['name'].value,
                emailAddress: e.target['emailAddress'].value,
                contact: e.target['contact'].value,
                message: e.target['message'].value

            };


            console.log(formData);
            // Send email using EmailJS
            emailjs.send(serviceID, templateID, formData, publicKey,)
                .then((response) => {
                    console.log('Email sent successfully:', response);
                    setResponse('Email sent successfully')
                    resetForm()
                })
                .catch((error) => {
                    console.error('Email failed to send:', error);
                    setResponse('Failed to send email')
                    resetForm()
                });
        }
    };
    return (
        <section className="contact-form inner-padding">
            <div className="contact-page-inner">
                <div className="container">
                    <div className="form-inner" data-aos="zoom-in-up">
                        {response ? <div className='text-center h3'>{response}</div> :
                            <form ref={formRef} onSubmit={sendEmail}>
                                <h1 className="sec_title text-center">Get in Touch
                                </h1>
                                <div className="two-list mb-30">
                                    <div className="input-div">
                                        <input type="text" placeholder="Full Name" name='name' />
                                        <label className='text-danger py-1'>{err?.name && err.name}</label>
                                    </div>
                                    <div className="input-div">
                                        <input type="tel" placeholder="Phone Number" name='contact' />
                                        <label className='text-danger py-1'>{err?.contact && err.contact}</label>
                                    </div>
                                    <div className="input-div">
                                        <input type="mail" placeholder="Email" name='emailAddress' />
                                        <label className='text-danger py-1'>{err?.emailAddress && err.emailAddress}</label>
                                    </div>
                                </div>
                                <div className="form-msg mb-30">
                                    <textarea placeholder="Message" name='message'></textarea>
                                    <label className='text-danger py-1'>{err?.message && err.message}</label>
                                </div>
                                <div className="submit-btn">
                                    <button type='submit' className="btn r-btn hover-btn wave-btn">Submit</button>
                                </div>
                                {response && (response === 'Email sent successfully' ? <div className='text-center alert alert-success success-alert common-alert' role="alert"><svg xmlns="http://www.w3.org/2000/svg" width="18px" height="18px" viewBox="0 0 24 24" fill="none">
                                    <path d="M12 2C6.49 2 2 6.49 2 12C2 17.51 6.49 22 12 22C17.51 22 22 17.51 22 12C22 6.49 17.51 2 12 2ZM16.78 9.7L11.11 15.37C10.97 15.51 10.78 15.59 10.58 15.59C10.38 15.59 10.19 15.51 10.05 15.37L7.22 12.54C6.93 12.25 6.93 11.77 7.22 11.48C7.51 11.19 7.99 11.19 8.28 11.48L10.58 13.78L15.72 8.64C16.01 8.35 16.49 8.35 16.78 8.64C17.07 8.93 17.07 9.4 16.78 9.7Z" fill="#292D32" />
                                </svg><span>{response}</span> </div> :
                                    <div className='text-center alert alert-danger danger-alert common-alert' role="alert"><svg xmlns="http://www.w3.org/2000/svg" width="18px" height="18px" viewBox="0 0 24 24" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10zm-1.5-5.009c0-.867.659-1.491 1.491-1.491.85 0 1.509.624 1.509 1.491 0 .867-.659 1.509-1.509 1.509-.832 0-1.491-.642-1.491-1.509zM11.172 6a.5.5 0 0 0-.499.522l.306 7a.5.5 0 0 0 .5.478h1.043a.5.5 0 0 0 .5-.478l.305-7a.5.5 0 0 0-.5-.522h-1.655z" fill="#000000" /></svg><span>{response}</span> </div>)}
                            </form>
                        }
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ContactForm