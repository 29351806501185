import React from 'react'
import { articles, categories, popularArticles } from '../../../constants/constants'
import ArticleCard from '../../commonCards/ArticleCard'
import RightArticleCard from '../../commonCards/RightArticleCard'
const Articles = () => {
  return (
    <section class="achievements blog-sec sec_pd">
      <div class="container">
        <div class="d-flex flex-wrap article-box">
          <div class="article-left-box" data-aos="fade-right">
            {articles.map(article => <ArticleCard {...article} />)}
          </div>

        </div>
      </div>
    </section>
  )
}

export default Articles