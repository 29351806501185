import React from 'react'
import MailchimpSubscribe from "react-mailchimp-subscribe"

const NewsLetter = () => {

    const url = "https://direct.us21.list-manage.com/subscribe/post?u=562e68c98a5c7f79c7d733d57&amp;id=75ae5acf5f&amp;f_id=0016fae6f0";

    // simplest form (only email)
    const SimpleForm = () => <MailchimpSubscribe url={url} />

    return (
        <section className="newsletter" data-aos="zoom-in" data-aos-delay="200" data-aos-duration="1400">
            <div className="container">
                <div className="show_bg">
                    <div className="row">
                        <div className="col-lg-6">
                            <h1 className="sec_title2">Subscribe to our Newsletter</h1>
                            <p>Sign up for our newsletter to stay on top of the latest news related to direct mail marketing.</p>
                        </div>
                        <div className="col-lg-6">
                            <MailchimpSubscribe
                                url={url}
                                render={({ subscribe, status, message }) => (
                                    <>
                                        <div style={{ display: 'block' }}>
                                            <div className='newsletter-input'>
                                                <SimpleForm className="n_inp_btn" onSubmitted={formData => subscribe(formData)} />

                                            </div>

                                        </div>
                                        <div>
                                            {status === "sending" && <div style={{ color: "blue" }}>sending...</div>}
                                            {status === "error" && <div style={{ color: "red" }} dangerouslySetInnerHTML={{ __html: message }} />}
                                            {status === "success" && <div style={{ color: "green" }}>Subscribed !</div>}
                                        </div>
                                    </>

                                )}
                            />

                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default NewsLetter